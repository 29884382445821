import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DatepickerService } from "app/_services/datepicker.service";
import { DetalhesContainerComponent } from 'app/shared/detalhes-container/detalhes-container.component';

import { TrackingLista } from 'app/_models/tracking-lista';
import { TipoContainer } from 'app/_models/tipo-container';
import { AgendaEntrega } from 'app/_models/agenda-entrega';

import * as moment from 'moment/moment';
import 'moment/locale/pt-br';

@Component({
  selector: 'item-entrega-agendamento',
  templateUrl: './item-entrega-agendamento.component.html',
  styleUrls: ['./item-entrega-agendamento.component.scss']
})
export class ItemEntregaAgendamentoComponent implements OnInit {

  @ViewChild(DetalhesContainerComponent, {static: true}) detalhesContainer: DetalhesContainerComponent;

  tipoContainer: TipoContainer;
  options: DatePickerOptions;
  mascaraHora: Array<string | RegExp> = [/[0-2]/,/[0-9]/, ':', /[0-5]/, /[0-9]/];

  @Input() index: number;
  @Input() dataSugerida: Date;
  @Input() tracking: TrackingLista;
  @Input() agendamento: AgendaEntrega;
  @Input() somenteLeitura: boolean;

  constructor(public _datePickerService: DatepickerService) {
    this.tipoContainer = new TipoContainer();
  }

  changeRegex(input_time){
    if(input_time.charAt(0) == '2'){
       this.mascaraHora[1]=new RegExp('[0-3]')
    }else{
       this.mascaraHora[1]=new RegExp('[0-9]')
    }
  }

  ngOnInit() {
    this.options = this._datePickerService.GenerateOptionsConfig(this.dataSugerida);//new Date());
    //this.tipoContainer.Desc_resumida = (this.booking) ? this.booking.tipoContainer : '';
  }

  exibeIcone(): boolean {
    return this.agendamento && this.agendamento.statusAgendamento && this.agendamento.statusAgendamento.length > 0;
  }

  icone(): string {
    if (!this.agendamento || !this.agendamento.statusAgendamento)
      return "";

    var status = this.agendamento.statusAgendamento.toLowerCase();
    if (status.indexOf("ativo") >= 0)
      return "check";
    if (status.indexOf("rejeitado") >= 0)
      return "forbidden";
    if (status == "solicitacao agendamento portal")
      return "alert";

    return "";
  }

  toolTipIcone(): string {
    if (!this.agendamento || !this.agendamento.statusAgendamento)
      return "";

    var status = this.agendamento.statusAgendamento.toLowerCase();
    if (status.indexOf("ativo") >= 0)
      return "Agendamento de Entrega Confirmado";
    if (status.indexOf("rejeitado") >= 0)
      return "Agendamento de Entrega Rejeitado";
    if (status == "solicitacao agendamento portal")
      return "Solicitação de Agendamento de Entrega Pendente de Confirmação";

    return "";

  }

  formataEndereco(): string{
    if (!this.agendamento)
      return "";

    //13340-730 - EST ANGELA TREVISAN CICILIATO, 200 - JD OLIVEIRA CAMARGO - SERRA/ES - (99) 999999999
    return ((this.agendamento.cepEnt) ? this.agendamento.cepEnt.trim() : "") + " - " +
           ((this.agendamento.logradouroEnt) ? this.agendamento.logradouroEnt.trim() : "") + ", " +
           ((this.agendamento.numeroEndEnt) ? this.agendamento.numeroEndEnt.trim() : "") + " " +
           ((this.agendamento.complementoEnt) ? this.agendamento.complementoEnt.trim() : "") + " - " +
           ((this.agendamento.bairroEnt) ? this.agendamento.bairroEnt.trim() : "") + " - " +
           ((this.agendamento.municipioEnt) ? this.agendamento.municipioEnt.trim() : "") + "/" +
           ((this.agendamento.ufEnt) ? this.agendamento.ufEnt.trim() : "") + " - " +
           ((this.agendamento.contatoEnt) ? this.agendamento.contatoEnt.trim() : "") + " - " +
           ((this.agendamento.dddEnt) ? "(" + this.agendamento.dddEnt.trim() + ")" : "") + " " +
           ((this.agendamento.foneEnt) ? this.agendamento.foneEnt.trim() : "");
  }

  formataData(formato: string): string{
    return (!this.agendamento || this.agendamento.numAgendamento == 0) ? "" : moment(this.agendamento.dataEntrega).format(formato);
  }

  formataHora(): string{
    console.log(this.agendamento.horaEntrega);
    const horaSplit = this.agendamento.horaEntrega.split(':')
    console.log(horaSplit)
    if(horaSplit.length != 2)
      return;
    const hora = horaSplit[0].length != 2? '0' + horaSplit[0]: horaSplit[0]
    const minutos = horaSplit[1].length != 2 && horaSplit[1] != ''? '0' + horaSplit[1]: horaSplit[1]
    this.agendamento.horaEntrega = hora + ':' + minutos;
  }
}
