import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { Porto } from './_models/porto';

import { PerfilModule } from './admin/perfil/perfil.module';
import { UsuarioModule } from './admin/usuario/usuario.module';
import { SobreModule } from './public/sobre/sobre.module';
import { LogModule } from './admin/log/log.module';
import { AnelRotaModule } from './admin/anel-rota/anel-rota.module';
import { ParametroModule } from './admin/parametro/parametro.module';
import { CaixaEntradaModule } from './admin/caixa-entrada/caixa-entrada.module';
import { VincularNfModule } from './provedor/vincular-nf/vincular-nf.module';
import { OrdemServicoModule } from './provedor/ordem-servico/ordem-servico.module';
import { RastreamentoModule } from './cliente/rastreamento/rastreamento.module';
import { CotacaoModule } from './cliente/cotacao/cotacao.module';
import { BookingModule } from './cliente/booking/booking.module';
import { CadastrarSenhaModule } from './public/cadastrar-senha/cadastrar-senha.module';
import { ResetSenhaModule } from './public/reset-senha/reset-senha.module';
import { HomeModule } from './public/home/home.module';
import { RegistrarModule } from './public/registrar/registrar.module';
import { FaleConoscoModule } from './public/fale-conosco/fale-conosco.module';
import { ProgramacaoNaviosModule } from './public/programacao-navios/programacao-navios.module';
import { DocumentacaoModule } from './cliente/documentacao/documentacao.module';
import { NgModule, LOCALE_ID, APP_INITIALIZER  } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from './shared/shared.module';
import { ConfirmModule } from './shared/confirm/confirm.module';
import { AppRoutingModule } from './app.routing.module';
import { MascaraModule } from "app/shared/mascara/mascara.module";

import { AppComponent } from './app.component';
import { LoginModalComponent } from './public/login-modal/login-modal.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { AppService } from './_services/app.service';
import { BaseService } from './_services/base.service';
import { DataService } from './_services/data.service';
import { SharedService } from './_services/shared.service';
import { ConfigService } from './_services/config.service';
import { MunicipioService } from './_services/municipio.service';
import { AgendaEntregaService } from "app/_services/agenda-entrega.service";
import { AuthenticationService } from './_services/authentication.service';
import { GrupoEconomicoService } from './_services/grupo-economico.service';

import { AuthGuard } from './_guards/auth.guard';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { LogService } from './_services/log.service';
import { MessageService } from './_services/message.service';
import { AnalyticsService } from './_services/analytics.service';
import { ConfigViaGreen } from './_models/config-portal';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClientModule } from '@angular/common/http';

import { ImportsNotUsedAsValues } from 'typescript';


import { IcmsModule } from './admin/icms/icms.module';
import { CustomerInfoImportModule } from './admin/customer-info-import/customer-info-import.module';
import { TipoTaxaEsporadicaModule } from './admin/tipo-taxa-esporadica/tipo-taxa-esporadica.module';
import { PortoModule } from './admin/porto/porto.module';

import { TaxaEsporadicaModule } from './admin/taxa-esporadica/taxa-esporadica.module';


import { SubTributariaModule } from './admin/sub-tributaria/sub-tributaria.module';

//import { RelatorioPixComponent } from './cliente/booking/relatorios/relatoriopix/relatoriopix.component';
import { RelatorioPixModule } from  './cliente/booking/relatorios/relatoriopix/relatoriopix.module';
import { Booking2Module } from './cliente/booking/relatorios/relatoriopix/booking2.module';

import { pid } from 'process';



export function loadConfig(config: ConfigService){
  return () => config.loadConfig();
}

export function loadGoogleConfig(config: ConfigService){
  return () => config.loadGoogleConfig();
}


export function loadViaGreenConfig(config: ConfigService){
  return () => config.loadViaGreenConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginModalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    ConfirmModule,
    MascaraModule,
    NgxMaskModule.forRoot(),
    DocumentacaoModule,
    ProgramacaoNaviosModule,
    FaleConoscoModule,
    RegistrarModule,
    HomeModule,
    ResetSenhaModule,
    CadastrarSenhaModule,
    BookingModule,
    CotacaoModule,
    RastreamentoModule,
    OrdemServicoModule,
    VincularNfModule,
    CaixaEntradaModule,
    ParametroModule,
    AnelRotaModule,
    LogModule,
    SobreModule,
    UsuarioModule,
    PerfilModule,
    IcmsModule,
    CustomerInfoImportModule,
    SubTributariaModule,
    TipoTaxaEsporadicaModule,
    PortoModule,
    TaxaEsporadicaModule,
    RelatorioPixModule,
    Booking2Module
  ],
  exports: [],
  providers: [
    AppService,
    ConfigService,
    { provide: APP_INITIALIZER, useFactory: loadConfig, deps: [ConfigService], multi: true },
    { provide: APP_INITIALIZER, useFactory: loadGoogleConfig, deps: [ConfigService], multi: true },
    { provide: APP_INITIALIZER, useFactory: loadViaGreenConfig, deps: [ConfigService], multi: true },
    BaseService,
    SharedService,
    LogService,
    AuthenticationService,
    AuthGuard,
    MunicipioService,
    DataService,
    AnalyticsService,
    AgendaEntregaService,
    GrupoEconomicoService,
    MessageService,
    { provide: LOCALE_ID, useValue: "pt-BR" }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule { }
