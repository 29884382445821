import { AnalyticsOperacao } from './../../../_enums/analytics-operacao';
import { Component, OnInit, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import swal from 'sweetalert2';

import { Booking } from '../../../_models/booking';
import { Endereco } from 'app/_models/endereco';
import { Schedule } from './../../../_models/schedule';
import { Feriado } from 'app/_models/feriado';
import { Municipio } from '../../../_models/municipio';
import { TipoContainer } from '../../../_models/tipo-container';
import { ParametroPorto } from 'app/_models/parametro-porto';
import { ClienteRequest } from 'app/_models/cliente-request';
import { PropostaComercial } from './../../../_models/proposta-comercial';

import { AppService } from './../../../_services/app.service';
import { BaseService } from 'app/_services/base.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from 'app/_services/message.service';
import { CotacaoService } from 'app/_services/cotacao.service';
import { BookingService } from './../../../_services/booking.service';
import { ParametroService } from './../../../_services/parametro.service';
import { PropostaComercialService } from './../../../_services/proposta-comercial.service';

import { BaseComponent } from './../../../shared/base.component';
import { MessageComponent } from 'app/shared/message/message.component';
import { ControleStepsComponent } from './controle-steps/controle-steps.component';
import { Step1SelecaoPropostaComponent } from './step1-selecao-proposta/step1-selecao-proposta.component';
import { Step2DetalhesOperacaoComponent } from './step2-detalhes-operacao/step2-detalhes-operacao.component';
import { Step3AgendamentoColetaComponent } from './step3-agendamento-coleta/step3-agendamento-coleta.component';
import { Step4DadosReservaComponent } from './step4-dados-reserva/step4-dados-reserva.component';
import { Step5InformacaoPagtoComponent } from './step5-informacao-pgto/step5-informacao-pgto.component';

import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from 'app/_enums/analytics-tela';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { Operacao } from 'app/_enums/operacao';
import { CustomerInfo } from 'app/_models/customer-info';
import { CustomerInfoService } from 'app/_services/customer-info.service';
import { parse } from 'path';
import { BookingRequest } from 'app/_models/booking-request';
import { MeusBookings } from 'app/_models/meus-bookings';
import { FiltrosLaterais } from 'app/_models/filtros-laterais';
import { DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import { DateExtensions } from 'app/shared/extensions/extensions.module';
import { DatepickerService } from 'app/_services/datepicker.service';


@Component({
  selector: 'app-solicitar-booking',
  templateUrl: './solicitar-booking.component.html',
  styleUrls: ['./solicitar-booking.component.scss']
})
export class SolicitarBookingComponent extends BaseComponent implements OnInit {
  propostaIdentificada = new EventEmitter<PropostaComercial>();

  qtdeContainersInicial: number = 1;
  parametroPorto: ParametroPorto = new ParametroPorto();
  booking: Booking = new Booking();
  loading: boolean = false;
  loadingSolicitar: boolean = false;
  loadingBooking: boolean = false;
  loadingParametro: boolean = false;
  loadingProposta: boolean = false;
  habilitaBotoesAbas: boolean = false;
  currentStep: number = 1;
  inscricaoGet: Subscription;
  novoBooking: boolean = true;
  edicaoBooking: boolean = false;
  erroBooking: boolean = false;
  duplicar = false;
  titulo: string;
  proposta: PropostaComercial;
  eNovoBookingOrigemCotacaoAtiva = false;

  progNavios: Schedule;
  feriados: Feriado[];

  mostraAbaPix: boolean = false;
  isInstantPaymentEfetivado: boolean = true;





  constructor(private _route: ActivatedRoute,
    private router: Router,
    private _bookingService: BookingService,
    private _propostaService: PropostaComercialService,
    private _appService: AppService,
    private _sharedService: SharedService,
    private _msgService: MessageService,
    private _cotacaoService: CotacaoService,
    private _parametroService: ParametroService,
    private _baseService: BaseService,
    private _analyticsService: AnalyticsService,
    private _customerInfoService: CustomerInfoService,
    private _datepickerService: DatepickerService) {

    super();

    this.proposta = new PropostaComercial();
    this._msgService.emitirMensagem.subscribe(
      msg => {
        if (msg.mensagem instanceof Array)
          this.mensagem.mostrarMensagemArray(msg.tipo, msg.mensagem);
        else
          this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  @ViewChild(Step1SelecaoPropostaComponent, { static: true }) step1: Step1SelecaoPropostaComponent;
  @ViewChild(Step2DetalhesOperacaoComponent, { static: true }) step2: Step2DetalhesOperacaoComponent;
  @ViewChild(Step3AgendamentoColetaComponent, { static: true }) step3: Step3AgendamentoColetaComponent;
  @ViewChild(Step4DadosReservaComponent, { static: true }) step4: Step4DadosReservaComponent;
  @ViewChild(Step5InformacaoPagtoComponent, { static: true }) step5!: Step5InformacaoPagtoComponent;



  @ViewChild(ControleStepsComponent, { static: true }) controlesteps: ControleStepsComponent;
  @ViewChild(MessageComponent, { static: true }) mensagem: MessageComponent;
  @ViewChild('modalResumo', { static: true }) modalResumo;
  @ViewChild('topo', { static: true }) private topo: ElementRef;




  ngOnInit() {
    if (this._appService.UsuarioLogado == null || this._appService.UsuarioLogado == undefined) {
      this._baseService.handleError({ status: 401 });
      return;
    }
    this._analyticsService.RegistrarOperacao(AnalyticsTela.Booking, AnalyticsOperacao.AcessoBooking);
    this.inscricaoGet = this._route.params.subscribe(
      (params: any) => {

        if (params['id'] == "programacao") {
          this._route.queryParams.subscribe(params => {
            this.progNavios = params as Schedule;
            this.step3.novoBooking = true;
            this.step4.novoBooking = true;
            this.step3.progNavios = this.progNavios;
            this.step4.progNavios = this.progNavios;
            this.step4.programacaoSelecionada = this.progNavios;
            this.booking.viagemNavio = this.progNavios.descNavioViagem;
            this.booking.idNavioViagem = this.progNavios.idViagemNavio;
            this.booking.idPortoTransbordo = this.progNavios.idPortoTransbordo;
            this.booking.idNavioTransbordo = this.progNavios.idNavioTransbordo;
          });
          this.novoBooking = true;
        }
        else {
          let id: number = params['id'];
          this.duplicar = (params['duplicar'] == undefined) ? false : params['duplicar'];
          const numProposta: number = params['numProposta'];
          const idTrecho: number = params['idTrecho'];
          const cnpjCli: string = params['cnpjCli'];
          const ieCli: string = params['ieCli'];

          if (id) {
            this.carregarBooking(id, (!numProposta));
          }

          this.novoBooking = !id || this.duplicar;
          //this.habilitaBotoesAbas = (!this.novoBooking);

          // 28/07/2017 Monta novo booking à partir da cotação com status 'Ativa'
          if (numProposta) {
            this.loadingBooking = true;
            this.eNovoBookingOrigemCotacaoAtiva = true;

            this.obterCotacaoPorNumPropostaEidTrecho(numProposta, idTrecho, cnpjCli, ieCli);
            this.propostaIdentificada.subscribe(proposta => {
              if (proposta) {
                this._sharedService.copyProperties(proposta, this.proposta);

                if (this.proposta.tipoDeContainer == undefined)
                  this.proposta.tipoDeContainer = new TipoContainer();

                this._sharedService.copyProperties(proposta.tipoDeContainer, this.proposta.tipoDeContainer);

                this.montaNovoBookinComBaseNaCotacaoAtiva();

                this.customerInfoValidation();


                // if (this.mostraAbaPix) {
                //   this.step5.onLoadInfoPgtoAdicional(this.booking.numBooking);
                // }

              }
            });
          }
        }
      }
    );

    this.step1.cnpjdropdown.SelecionaMultiplos = false;
    this.step1.cnpjdropdown.modoEdicao = true;

    if (this.novoBooking) {
      this.step1.cnpjdropdown.modoBloqueado = this.duplicar;
      this.step1.cnpjdropdown.pesquisaEspecial = this._appService.maisDeUmCNPJ() && !this.duplicar;
      this.titulo = 'Novo Booking';
    }
    else {
      this.step1.cnpjdropdown.SelecionaMultiplos = false;
      this.step1.cnpjdropdown.modoBloqueado = this.duplicar;
      this.step1.cnpjdropdown.pesquisaEspecial = false;
      this.titulo = 'Consultar Booking';
    }
  }

  montaNovoBookinComBaseNaCotacaoAtiva() {
    if (this.proposta) {
      // novo booking com origem da cotação com status 'ativa'
      this.novoBooking = true;

      // preenche booking para filtrar proposta respectiva
      const booking: Booking = new Booking();
      booking.numProposta = this.proposta.numProposta.toString();
      booking.idTrecho = this.proposta.idTrecho;
      booking.modal = this.proposta.modal;
      booking.cnpjCli = this.proposta.cnpjCli;
      booking.ieCli = this.proposta.ieCli;
      booking.qtdeContainer = 0;//this.proposta.qtdeContainer;
      if (this.proposta.tipoDeContainer != undefined)
        this.booking.tamanhoContainer = this.proposta.tipoDeContainer.Tamanho;

      this.booking = booking;

      //this.habilitaBotoesAbas = true;
      this.step1.bookingAPartirDeCotacao = true;
      this.booking.proposta = this.proposta;

      this.step1.booking = booking;
      this.step2.booking = this.step1.booking;
      this.step3.booking = this.step2.booking;
      this.step4.booking = this.step3.booking;

      this.loadingBooking = false;
      this.preCarregamentoDeCNPJeIE(booking);

      this.customerInfoValidation();

    }
  }

  obterCotacaoPorNumPropostaEidTrecho(numProposta: number, idTrecho: number, cnpj: string, ie: string) {
    var consulta = { numProposta: numProposta, usuarioID: this._appService.UsuarioLogado.UsuarioIDeCargo, idTrecho: idTrecho, cnpjCli: cnpj, ieCli: ie };
    this._propostaService.getProposta(consulta)
      .subscribe(prop => {
        if (prop.qtdeContainer > 300) {
          swal({
            title: 'Alerta',
            text: 'A cotação selecionada possui mais de 300 conteineres. Entre em contato com o Custom Service para prosseguir com sua solicitação!',
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: 'OK'
          }).then(success => {
            this.router.navigate(['/cotacao']);
          }, dismiss => {
          });
        }
        else
          this.propostaIdentificada.emit(prop);
      });
  }


  carregouInfoPgto(carregou: boolean) {
    this.isInstantPaymentEfetivado = carregou;
    console.log('salvouInfoPix: ' + this.isInstantPaymentEfetivado);
  }

  quantidadeContainersAlterada(quantidadeContainers: number) {
    if (this.proposta != undefined)
      this.step3.carregarProposta(this.proposta, true);
  }

  carregarBooking(id, habilitaBotoes: boolean) {
    this.loading = true;
    this._bookingService.getBooking({ bookingID: id, usuarioID: this._appService.UsuarioLogado.UsuarioIDeCargo })
      .subscribe(book => {
        if (book != null) {
          this.qtdeContainersInicial = !this.duplicar && (book.qtdeContainer > 0) ? book.qtdeContainer : 1;

          book.idProposta = Number(book.numProposta);
          this._sharedService.copyProperties(book, this.booking);

          this.preCarregamentoDeCNPJeIE(book);

          //this.customerInfoValidation();

          if (this.duplicar) {
            //Limpar observacao de rejeição
            this.booking.obsRejeicaoCli = '';

            //busca objeto cliente para Embarcador
            var filtroCliente: ClienteRequest = new ClienteRequest;
            filtroCliente.CNPJ = this.booking.cnpjEmb;
            filtroCliente.InscricaoEstadual = this.booking.ieEmb;
            this._sharedService.getClientes(filtroCliente, false)
              .subscribe(c => {
                if (c && c.length == 1) {
                  this.booking.Embarcador = c[0];
                  this.step3.listaDeEnderecos = c[0].Enderecos;
                  //console.log(c[0].Enderecos);
                  var enderecos: Endereco[] = c[0].Enderecos.filter(e => e.CEP == book.cepEmb && e.Bairro == book.bairroEmb &&
                    (e.Complemento == book.complementoEmb || (!e.Complemento && !book.complementoEmb)) &&
                    e.IdMunicipio == book.idMunicEmb && e.Logradouro == book.logradouroEmb && e.Municipio == book.municipioEmb &&
                    e.Numero == book.numeroEmb && e.UF == book.ufEmb);
                  //console.log(enderecos);
                  if (enderecos && enderecos.length == 1)
                    this.step2.enderecoEmbSelecionado = enderecos[0];
                }
              });

            this.booking.qtdeContainer = 0;
            this.booking.DataPrevChegadaDes = undefined;
            this.booking.DataPrevChegadaOri = undefined;
            this.booking.DataDeadlineEntregaEmb = undefined;
            this.booking.contatoEmb = this._appService.UsuarioLogado.Nome.substring(0, 30);
            this.booking.email = this._appService.UsuarioLogado.Email;
            this.booking.emailEmb = this._appService.UsuarioLogado.Email.toLowerCase();
            this.booking.telefone = this._appService.UsuarioLogado.Telefone;
            this.booking.strDataPrevChegadaDes = '';
            this.booking.strDataPrevChegadaOri = '';
            this.booking.strDataDeadlineEntregaEmb = '';
            this.booking.viagemNavio = '';
            this.booking.idViagemNavio = 0;
            this.booking.idNavioViagem = 0;
            this.booking.idNavioViagemTransb = 0;
            this.booking.NavioViagemTransb = '';
          }
          else {
            //Corrige datas
            this.booking.DataPrevChegadaDes = this._sharedService.corrigeData(book.DataPrevChegadaDes);
            this.booking.DataPrevChegadaOri = this._sharedService.corrigeData(book.DataPrevChegadaOri);
            this.booking.DataDeadlineEntregaEmb = this._sharedService.corrigeData(book.DataDeadlineEntregaEmb);
            this.booking.email = (!this.booking.emailEmb) ? this.booking.emailEmb : this.booking.emailEmb.toLowerCase();
            this.booking.telefone = (book.foneEmb == 'NA') ? '' : book.dddEmb + book.foneEmb;
          }

          this.edicaoBooking = (book.statusBooking != null) &&
            (!this.duplicar) &&
            (book.statusBooking.toLowerCase().indexOf('pendente') > 0);
          //((book.statusBooking.toLowerCase().indexOf('pendente') > 0) || (book.statusBooking.toLowerCase().indexOf('ativo') >= 0));

          if (book.AgendamentosDeColeta && !this.duplicar) {
            book.AgendamentosDeColeta.forEach(agendamento => {
              agendamento.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
              agendamento.idBooking = this.booking.idBooking;
              if (agendamento.dataColeta && agendamento.dataColeta.toString().length == 19)
                agendamento.horaColeta = agendamento.dataColeta.toString().substr(11, 5);
              agendamento.dataColeta = this._sharedService.corrigeData(agendamento.dataColeta);
              agendamento.numAgendamento = this.duplicar ? 0 : agendamento.numAgendamento;
            });
          }

          if (this.booking.municipioEmb && this.booking.municipioEmb != "") {
            var mun = new Municipio();
            mun.Descricao = this.booking.municipioEmb;
            mun.Codigo = this.booking.idMunicipioEmb
            mun.UF = this.booking.ufEmb;
            mun.DescricaoCompleta = this.booking.municipioEmb + '/' + this.booking.ufEmb;
            this.step2.municipioEmb = mun;
          }

          if (!this.duplicar)
            this.booking.AgendamentosDeColeta = book.AgendamentosDeColeta;

          this.booking.numeroEmb = this.booking.numeroEndEmb;
          this.booking.nomeEmbarcador = this.booking.nomeEmb;

          this.step2.booking = this.booking;
          this.step2.telefoneEmbarcador = this.booking.telefone;

          if (this.booking.tipoDeContainer != undefined)
            this.booking.tamanhoContainer = this.booking.tipoDeContainer.Tamanho;

          this.loadingProposta = true;
          this._propostaService.getProposta({ numProposta: book.numProposta, usuarioID: this._appService.UsuarioLogado.UsuarioIDeCargo, idTrecho: book.idTrecho, cnpjCli: book.cnpjCli, ieCli: book.ieCli })
            .subscribe(proposta => {
              if (!proposta) {
                this.currentStep = -1;
                this._msgService.addMessageRedirect('Booking', 'Booking/Proposta ' + this.booking.numBooking + '/' + this.booking.numProposta + ' não encontrado para esse cliente (' + this.booking.cnpjCli + '). Contate o administrador!', 'error');
                this.retornarAoBooking();
                return;
              }
              this.ObterParametrosPorto(proposta);
              this.ObterFeriados(proposta);
              this.booking.proposta = proposta;
              this.proposta = proposta;
              this.step1.configuraPropostaSelecionada(proposta);
              this.step2.propostaAlterada(proposta);
              this.step3.carregarProposta(proposta, true);

              if (habilitaBotoes)
                this.habilitaBotoesAbas = (this.duplicar) ? true : (!this.novoBooking);

              if (proposta.status != undefined && !proposta.status.toLowerCase().startsWith('ativ'))
                this.edicaoBooking = false; //não permite edição de proposta vencida ou cancelada

              if (this.booking.qtdeContainer != this.booking.AgendamentosDeColeta.filter(a => a.numAgendamento != 0).length) {
                this.edicaoBooking = false;
                this.novoBooking = false;
                this.erroBooking = true;
              }

              this.loadingProposta = false;
            });

          this.step4.CarregaViagemBooking(book);

          //Mostra aba Info Pgto
          //this.customerInfoValidation() ;


          // if (this.mostraAbaPix) {
          //   this.step5.onLoadInfoPgtoAdicional(this.booking.numBooking);
          // }
        }

        else if (habilitaBotoes)
          this.habilitaBotoesAbas = (!this.novoBooking);

        this.loading = false;
      }, (err) => {
        this.loading = false;
        // console.log(err);
      });
  }

  preCarregamentoDeCNPJeIE(booking: Booking): void {
    /*
    if (!this.step1.cnpjdropdown.isUsuarioAdmin) {
      this.step1.cnpjdropdown.CNPJFiltro = booking.cnpjCli;
      this.step1.cnpjdropdown.cnpjsSelecionados = [];
      this.step1.cnpjdropdown.cnpjsSelecionados.push(booking.cnpjCli);
    }
    */
    this.step1.cnpjdropdown.CNPJDigitado = booking.cnpjCli;
    this.step1.cnpjdropdown.filtroCliente.CNPJ = booking.cnpjCli;
    this.step1.cnpjdropdown.filtroCliente.InscricaoEstadual = booking.ieCli;
    this.step1.cnpjdropdown.cnpjsSelecionados = [];
    this.step1.cnpjdropdown.cnpjsSelecionados.push(booking.cnpjCli);
    this.step1.cnpjdropdown.ObterClientes();
  }

  cssClass(step: number): string {
    if (step < this.currentStep)
      return 'completed';
    else
      if (step == this.currentStep)
        return 'active';
      else
        return 'disable';
  }

  iconClass(step: number): string {
    if (step < this.currentStep)
      return 'icon icon-check';
    else
      return 'icon icon-circle-o';
  }

  mudarStep(step: number) {
    //limpa mensagens de erros
    this._msgService.clearMessage();
    var mensagensErro: string[] = [];

    //atualiza endereco
    //if (this.currentStep == 3)
    if (!this.booking.oriPorto) {
      if (step == 3 || step == 4) {
        if (step == 3)
          this.step3.CarregaEndereco();
        this.step3.CarregarDadosAgendamentos();
      }
    }

    if (step == 2 && this.currentStep == 1) { //this.eNovoBookingOrigemCotacaoAtiva) && (
      this.step2.propostaAlterada(this.step1.propostaSelecionada);
      this.step3.carregarProposta(this.step1.propostaSelecionada, false);
    }

    if (!this.novoBooking && !this.edicaoBooking) {
      this.currentStep = step;
    }
    else {
      if (this.currentStep > step) {
        // está voltando nas abas, não requer validação
        this.currentStep = step;
      }
      else {
        if ((this.currentStep + 1 != step) && (!((this.booking.oriPorto) && (this.currentStep == 2) && (step == 4))) && (this.novoBooking || this.edicaoBooking || this.duplicar))
          mensagensErro.push('Preencha uma aba por vez!');
        else {
          //Valida abas
          switch (this.currentStep) {
            case 1:
              if (this.step1.propostaSelecionada == undefined)
                mensagensErro = mensagensErro.concat('Para continuar é necessário informar a proposta comercial.');
              break;
            case 2:
              mensagensErro = mensagensErro.concat(this.step2.ValidarStep());
              break;
            case 3:
              var errosStep3 = this.step3.ValidarStep();
              if (errosStep3.length != 0)
                mensagensErro = mensagensErro.concat(errosStep3);
              else {
                if (this.booking && this.booking.AgendamentosDeColeta) {
                  this.booking.AgendamentosDeColeta.forEach(a => {
                    a.strValMercadoria = this._sharedService.formatNumeroMoeda(a.valMercadoria, 2, 'R$ ', '.', ',');
                    a.strDataColeta = this._sharedService.formataDataHoraBR(a.dataColeta, false);
                  });
                }
              }
              break;
            //case 4:
            //valida step4
            //break;
            case 5:
              mensagensErro = mensagensErro.concat(this.step5.ValidarStep());
              break;              
          }
        }
      }

      if (mensagensErro.length == 0) {
        //if (step == 2) {
        // Caso usuário tenha voltado na step 01 e tenha alterado o modal,
        // devemos recarregar a tela (modal é parâmetro de filtro em propostas)
        //if (this.step2.carregarAba || (this.booking.modal != this.step2.modal)) {
        //  this.step2.InicializaStep2(this.step1.booking);
        //}
        //}

        if (step == 4) {
          //if ((!this.step4.abaCarregada) || (this.step2.carregarAba || (this.booking.modal != this.step2.modal))) {
          if (!this.progNavios && !this.edicaoBooking)
            this.step4.BuscaViagens();
          //}
        }
        this.currentStep = step;
      }
      else
        if (mensagensErro.length > 0)
          this._msgService.addMessageArray('Booking - Mudar para Step ' + step, mensagensErro, 'error', LogFrontFuncionalidade.Booking, Operacao.Consulta);
    }

    if (this.currentStep == step) {
      setTimeout(() => { this.topo.nativeElement.scrollIntoView(); });
    }
  }

  retornarAoBooking() {
    this.router.navigate(['/booking']);
  }

  propostaSelecionada(proposta: PropostaComercial) {
    this._sharedService.copyProperties(proposta, this.proposta);
    this.ObterParametrosPorto(proposta);
    this.ObterFeriados(proposta);
    if (proposta != undefined)
      this.proposta.tipoDeContainer = proposta.tipoDeContainer;
    this.habilitaBotoesAbas = (proposta != undefined);
    this.step2.propostaAlterada(proposta);
    this.step3.carregarProposta(proposta, false);
    this.step5.propostaAlterada(proposta);

    this.customerInfoValidation();
  }

  Salvar() {
    this.modalResumo.hide();

    if (this._appService.isAdmin()) {
      this._msgService.addMessage('Booking', 'Usuário administrador não tem permissão para adicionar bookings. Essa funcionalidade é restrita a clientes.', 'warning');
      return;
    }

    this.booking.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
    //this.booking.contatoCli = this._appService.UsuarioLogado.Nome.substr(0, 30);
    this.booking.setFlagsEnvio();

    if (this.booking.telefone == "") {
      this.booking.dddEmb = this._sharedService.obtemDDDdoTelefone(this.booking.foneEmb);
      this.booking.telefone = this._sharedService.obtemTelefoneSemDDD(this.booking.foneEmb);
    }

    this.booking.contatoCli = (!this.proposta.contatoCli && this.proposta.contatoCli != null && this.proposta.contatoCli != "") ? this.proposta.contatoCli : this._appService.UsuarioLogado.Nome;
    this.booking.contatoCli = this.booking.contatoCli.substring(0, 30);
    //ajustado no converter
    //if (this.duplicar) {
    //  this.booking.idProposta = Number(this.booking.numProposta);
    //  this.booking.idMunicEmb = this.booking.idMunicipioEmb;
    //}

    this.loadingSolicitar = true;
    if (this.novoBooking) {
      if (this.duplicar)
        this._analyticsService.RegistrarOperacao(AnalyticsTela.Booking, AnalyticsOperacao.Duplicacao);

      this._bookingService.Solicitar(this.booking)
        .subscribe(result => {
          this.loadingSolicitar = false;
          if (result != null) {
            this._msgService.addMessageRedirect('Booking', 'Booking Número ' + result.numBooking + ' gerado com sucesso!', 'success');

            // Tratamento para clientes que pagam a cabotagem à vista.
            if (this.mostraAbaPix == true) {
              this.booking.numBooking = result.numBooking;
              this.mudarStep(5);
              this.step5.qtdeContainer = this.booking.qtdeContainer;
              

              let dataTmp: Date;
  
              const modal = this.booking.modal.split("/");
              

              if (modal[0] == 'PA') {
          
                let cteIssueDate: DateModel = DateExtensions.toDateModel(this.GetDataUltimoAgendamento(), "DD/MM/YYYY");
                dataTmp = this.ObterData(cteIssueDate, 0);
              }
              else {
          
                let cteIssueDate: DateModel = DateExtensions.toDateModel(this.booking.dhDeadlineEntregaEmbarque, "DD/MM/YYYY");
                dataTmp = this.ObterData(cteIssueDate, 0);
              }
          
              let ano = dataTmp.getFullYear();
              let mes = '';
              let dia = '';
          
              if (dataTmp.getMonth() > 9)
                mes = (dataTmp.getMonth() + 1).toString();
              else
                mes = '0' + (dataTmp.getMonth() + 1).toString();
          
          
              if (dataTmp.getDate() > 9)
                dia = dataTmp.getDate().toString();
              else
                dia = '0' + dataTmp.getDate().toString();


                
              this.step5.dataBaseCalculo =  dia+ '/' + mes + '/' +ano  ;

              this.step4.isBookingSaved = true;

              const bookingBTN = document.getElementById('bookingBTN') as HTMLButtonElement;
              bookingBTN.disabled = true;

            }
            else {
              this.retornarAoBooking();
            }
          }
          //else {
          //  this._msgService.addMessage('Cotação', "Erro ao solicitar cotação", "error");
          //}
        });
    }
    else {
      this._bookingService.AlterarBooking(this.booking)
        .subscribe(result => {
          this.loadingSolicitar = false;
          if (result != null) {
            this._msgService.addMessageRedirect('Booking', 'Booking atualizado com sucesso', 'success');
            this.retornarAoBooking();
          }
          //else {
          //  this._msgService.addMessage('Booking', 'Erro ao atualizar booking', 'error');
          //}
        });
    }
  }

  obtemDescricaoConteiner() {
    if (this.proposta != undefined) {
      if (this.proposta.tipoDeContainer != undefined)
        return this.proposta.tipoDeContainer.Descricao + " - " + this.proposta.tipoDeContainer.Desc_resumida;
    }

    if (this.booking != undefined) {
      if (this.booking.tipoDeContainer != undefined)
        return this.booking.tipoDeContainer.Descricao + " - " + this.booking.tipoDeContainer.Desc_resumida;
    }
    return "";
  }

  ObterParametrosPorto(proposta: PropostaComercial) {
    if (!proposta)
      return;

    this.loadingParametro = true;
    this._parametroService.obterParametrosPorto(proposta.idPortolOrigem)
      .subscribe(parametro => {
        this.loadingParametro = false;
        this.parametroPorto = parametro;
      });
  }

  ObterFeriados(proposta: PropostaComercial) {
    if (!proposta)
      return;

    this._sharedService.getFeriados(proposta.idPortolOrigem)
      .subscribe(feriados => {
        if (feriados)
          this.feriados = feriados.filter(f => f.Data != null);
        else
          this.feriados = null;
      });
  }

  getTextoValorOOG(valor): string {
    return (valor) ? valor.toString() + " cm" : "";
  }

  obtemTextoOriDes(oriDes: boolean, portoPorta: boolean) {
    if (portoPorta) {
      //PortO
      return (oriDes) ? 'Porto Origem: ' : 'Porto Destino: ';
    }
    else {
      //PortA
      return (oriDes) ? 'Município Origem: ' : 'Município Destino: ';
    }
  }

  onClickRegistrarPagamento() {
    this.step5.onSaveInfoPgtoAdicional();
  }

  customerInfoValidation() {
    this.loadingParametro = true;
    this.step5.isDuplicating = false;
    let consulta: BookingRequest = new BookingRequest();

    consulta.idBooking = 0;
    consulta.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
    consulta.cnpjCli = this.booking.cnpjCli;
    let sucesso: number = 0;

    //if (registrarAnalytics)
   //this._analyticsService.RegistrarFiltroBooking(this.consulta);

    this._bookingService.getBookings(consulta)
      .subscribe(meusBookings => {
        if (meusBookings != null)

          console.log(sucesso);

      });



    this._customerInfoService.getCustomerInfo(this.booking.cnpjCli)
      .subscribe(customerInfo => {
        this.loadingParametro = false;

        if (customerInfo) {
         
          this.mostraAbaPix = true;

          //if (this.duplicar || this.edicaoBooking)
          {
            this.step5.onLoadInfoPgtoAdicional(this.booking.numBooking);
            this.step5.isDuplicating = true;
          }
        }
        else {
          this.mostraAbaPix = false;
        }
      });

    return this.mostraAbaPix;
  }

  instantPaymentPendente(isCriado: boolean) {
    this.isInstantPaymentEfetivado = !isCriado;
  }

  private ObterData(dateModel: DateModel, dateOffset: number) {
    return dateModel != undefined
      ? this._datepickerService.ObtemData(dateModel)
      : this._sharedService.addDays(new Date(), dateOffset);
  }

  private GetDataUltimoAgendamento(): Date {
    let dataUltimoAgendamento: Date;

    let arr = this.booking.AgendamentosDeColeta;
    let maxDate: Date = new Date();

    for (let i = 1, len = arr.length; i < len; i++) {

      let v = arr[i].dataColeta;

      maxDate = (v > maxDate) ? v : maxDate;
    }

    return maxDate;
  }

}
