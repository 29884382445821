
import {catchError, map} from 'rxjs/operators';
import { DetalhesTrackingRequest } from "./../_models/detalhes-tracking-request";
import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { Observable } from "rxjs";

import { BaseService } from "./base.service";
import { SharedService } from "app/_services/shared.service";
import { MessageService } from "./message.service";

import { EventoTracking } from "app/_models/evento-tracking";
import { TrackingCteNf } from "app/_models/tracking-cte-nf";
import { ValidationResult } from "./../_models/validation-result";
import { TrackingDownload } from "../_models/tracking-download";
import { TipoEventoTracking } from "./../_models/tipoEventoTracking";
import { EventoTrackingRequest } from "app/_models/evento-tracking-request";
import { TrackingListaRequest } from "app/_models/trackinglista-request";
import { RastreamentoCargasVM } from "app/_services/rastreamento-cargas-vm";

import { Operacao } from "../_enums/operacao";
import { LogFrontFuncionalidade } from "../_enums/log-front-funcionalidade";
import { TrackingDetalhe } from "app/_models/tracking-detalhe";
import { DetalhesRotasViagens } from "app/_models/detalhes-rotas-viagens";
import { Co2TotalRequest } from "app/_models/co2-total-request";
import { Co2Total } from "app/_models/co2-total";

@Injectable({
  providedIn: 'root'
})
export class RastreamentoService {
  constructor(
    private baseService: BaseService,
    private _msgService: MessageService,
    private _sharedService: SharedService
  ) {}

  obtemRastreamentosDeCargas(
    consulta: TrackingListaRequest
  ): Observable<RastreamentoCargasVM> {
    return this.obtemRastreamento(consulta, "");
  }

  obtemRastreamentosDownloadCSV(
    consulta: TrackingListaRequest
  ): Observable<RastreamentoCargasVM> {
    return this.obtemRastreamento(consulta, "/csv");
  }

  private obtemRastreamento(
    consulta: TrackingListaRequest,
    url: string
  ): Observable<RastreamentoCargasVM> {
    return this.baseService
      .Post("tracking" + url, consulta).pipe(
      map((response: ValidationResult) => {
        const retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError(
            "Rastreamento",
            retorno.Erros,
            "error",
            LogFrontFuncionalidade.Rastreamento,
            Operacao.Consulta,
            consulta
          );
        }

        // RastreamentoCargasVM
        const rastreamentoCargasVM = retorno.Data as RastreamentoCargasVM;
        rastreamentoCargasVM.erros = retorno.Erros;
        return rastreamentoCargasVM;
      }),
      catchError((e) => {
        return this.baseService.handleError(e);
      }),);
  }

  obtemDetalhesTracking(
    consulta: DetalhesTrackingRequest
  ): Observable<TrackingDetalhe> {
    return this.baseService
      .Post("tracking/detalhes", consulta).pipe(
      map((response: ValidationResult) => {
        const retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError(
            "Rastreamento - Detalhes",
            retorno.Erros,
            "error",
            LogFrontFuncionalidade.Rastreamento,
            Operacao.Consulta,
            consulta
          );
        }

        return retorno.Data as TrackingDetalhe;
      }),
      catchError((e) => {
        return this.baseService.handleError(e);
      }),);
  }

  obtemEventosTracking(
    consulta: EventoTrackingRequest
  ): Observable<EventoTracking[]> {
    return this.baseService
      .Post("tracking/eventos", consulta).pipe(
      map((response: ValidationResult) => {
        const retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError(
            "Rastreamento - Eventos",
            retorno.Erros,
            "error",
            LogFrontFuncionalidade.Rastreamento,
            Operacao.Consulta,
            consulta
          );
        }

        return retorno.Data as EventoTracking[];
      }),
      catchError((e) => {
        return this.baseService.handleError(e);
      }),);
  }

  obtemTiposEventosTracking(
    idBooking: number
  ): Observable<TipoEventoTracking[]> {
    var url: string = "tracking/tiposeventos";
    if (idBooking) url = url + "/" + idBooking;

    return this.baseService
      .Get(url).pipe(
      map((response: ValidationResult) => {
        const retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError(
            "Rastreamento - Eventos",
            retorno.Erros,
            "error",
            LogFrontFuncionalidade.Rastreamento,
            Operacao.Consulta,
            idBooking
          );
        }

        return retorno.Data as TipoEventoTracking[];
      }),
      catchError((e) => {
        return this.baseService.handleError(e);
      }),);
  }

  obtemCTeNfTracking(
    consulta: EventoTrackingRequest
  ): Observable<TrackingCteNf[]> {
    return this.baseService
      .Post("tracking/ctes", consulta).pipe(
      map((response: ValidationResult) => {
        const retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError(
            "Rastreamento - CT-e (xml)",
            retorno.Erros,
            "error",
            LogFrontFuncionalidade.Rastreamento,
            Operacao.Consulta,
            consulta
          );
        }

        return retorno.Data as TrackingCteNf[];
      }),
      catchError((e) => {
        return this.baseService.handleError(e);
      }),);
  }

  obtemTrackingsDownload(
    consulta: TrackingListaRequest
  ): Observable<TrackingDownload[]> {
    return this.baseService
      .Post("tracking/csv", consulta).pipe(
      map((response: ValidationResult) => {
        const retorno: ValidationResult = response;

        if (retorno.IsValid) {
          return retorno.Data as TrackingDownload[];
        } else {
          var erros: string[] = [];
          erros.push(
            "Erro ao buscar dados para o download! Contate o administrador!"
          );
          retorno.Erros.forEach((element) => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray(
            "Rastreamento",
            erros,
            "error",
            LogFrontFuncionalidade.Rastreamento,
            Operacao.Consulta,
            consulta
          );
          return null;
        }
      }),
      catchError((e) => {
        return this.baseService.handleError(e);
      }),);
  }

  exportarRelatorioCSV(rastreamentos: any[]) {
    //TrackingDownload[]) {
    if (!rastreamentos || rastreamentos.length === 0) {
      return rastreamentos;
    }

    const data: any[] = [];
    const titulos: any[] = [
      "CNPJ Cliente",
      "Razão Social Cliente",
      "Razão Social Embarcador",
      "CNPJ Destinatário",
      "Razão Social Destinatário",
      "Inscrição Estadual Destinatário",
      "CEP Destinatário",
      "Endereço Destinatário",
      "Booking",
      "Contêiner",
      "Navio/Viagem",
      "Navio/Viagem Transbordo",
      "Porto Transbordo",
      "UF Porto Transbordo",
      "Município Coleta",
      "Porto Origem",
      "UF Porto Origem",
      "Terminal Origem",
      "Município Entrega",
      "Porto Destino",
      "UF Porto Destino",
      "Terminal Destino",
      "Data Saída Porto Destino",
      "Status Booking",
      "Tipo Contêiner",
      "Categoria Contêiner",
      "Tamanho Contêiner",
      "Modal",
      "Carga Perigosa",
      "Carga Excesso",
      "Carga Refrigerada",
      "Data Solicitação Booking",
      "Data Booking Confirmado",
      "Data Retirada do Vazio",
      "Data Previsão Coleta",
      "Data Coleta Iniciada",
      "Data Saída do Cliente Coleta",
      "Data Depositada - Chegada Terminal",
      "Data de Embarque Navio Origem",
      "Data Chegada Prevista Navio Origem",
      "Data Chegada Realizada Navio Origem",
      "Data Saída Navio Realizada Origem",
      "Data Descarregada Navio Origem",
      "Data Previsão Chegada Transbordo",
      "Data Chegada Realizada Transbordo",
      "Saída Realizada Transbordo",
      "Embarcada Transbordo",
      "Data Chegada Prevista Navio Destino",
      "Data Chegada Realizada Navio Destino",
      "Descarregada Navio Destino",
      "Data Em Entrega - Saída Terminal",
      "Data Previsão Entrega",
      "Agendamento de Entrega Realizado",
      "Data Entrega Iniciada",
      "Data Saída do Cliente Entrega",
      "Data Realizado - Devolução do Vazio",
      "Notas Fiscais",
      "Ctes",
      "Referência Cliente",
      "Nome / Viagem do Navio Transbordo 1",
      "Nome Porto Transbordo 1",
      "UF Porto Transbordo 1",
      "Data Prev Chegada Transbordo 1",
      "Data Real Chegada Transbordo 1",
      "Nome / Viagem do Navio Transbordo 2",
      "Nome Porto Transbordo 2",
      "UF Porto Transbordo 2",
      "Data Prev Chegada Transbordo 2",
      "Data Real Chegada Transbordo 2",
      "Nome / Viagem do Navio Transbordo 3",
      "Nome Porto Transbordo 3",
      "UF Porto Transbordo 3",
      "Data Prev Chegada Transbordo 3",
      "Data Real Chegada Transbordo 3",
      "Nome / Viagem do Navio Transbordo 4",
      "Nome Porto Transbordo 4",
      "UF Porto Transbordo 4",
      "Data Prev Chegada Transbordo 4",
      "Data Real Chegada Transbordo 4",
      "Data Embarque Transbordo 1",
      "Data Desembarque Transbordo 1",
      "Data Embarque Transbordo 2",
      "Data Desembarque Transbordo 2",
      "Data Embarque Transbordo 3",
      "Data Desembarque Transbordo 3",
      "Data Embarque Transbordo 4",
      "Data Desembarque Transbordo 4"
    ];
    const cabecalho: any[] = [
      "*Os bookings pedidos via e-mail não terão a informação “Data/Hora Pedido de Booking” disponibilizada neste arquivo.",
      "* As informações de rastreamento são preenchidas conforme acontecem e são registradas em nossos sistemas. O prazo para registro é de até 6 horas úteis.",
      "",
    ];

    rastreamentos.forEach((x: TrackingDownload) => {
      data.push([
        x.cnpjCli,
        x.nomeCli,
        x.nomeEmb,
        x.cnpjDes,
        x.nomeDes,
        x.ieDes,
        x.cepDes,
        [x.logradouroDes, x.numeroEndDes, x.bairroDes, x.complementoDes, x.municipioDes, x.ufDes,].join(', '),
        x.numBooking,
        x.numContainer,
        x.viagemNavio,
        x.viagemNavioTrans,
        x.portoTransbordo,
        x.ufPortoTransbordo,
        x.municColeta,
        x.portoOri,
        x.ufPortoOri,
        x.terminalOri,
        x.municEntrega,
        x.portoDes,
        x.ufPortoDes,
        x.terminalDes,
        this._sharedService.formataDataCSV(x.dataSaidaPortoDestino),
        x.statusBooking,
        x.tipoContainer,
        x.categoriaContainer,
        x.tamanhoContainer,
        x.modal,
        x.flagCargaPerigosa,
        x.flagCargaOver,
        x.flagCargaRefrigerada,
        this._sharedService.formataDataCSV(x.dtSolicitBooking),
        this._sharedService.formataDataCSV(x.dtBookingConf),
        this._sharedService.formataDataCSV(x.dataRetiradaVazio),
        this._sharedService.formataDataCSV(x.dtPrevColeta),
        this._sharedService.formataDataCSV(x.dataRealColeta),
        this._sharedService.formataDataCSV(x.dataFimColeta),
        this._sharedService.formataDataCSV(x.dtRealDepositada),
        this._sharedService.formataDataCSV(x.dataEmbarquePOL),
        this._sharedService.formataDataCSV(x.dataEtaPolPrev),
        this._sharedService.formataDataCSV(x.dataEtaPolConf),
        this._sharedService.formataDataCSV(x.dtRealSaidaNavio),
        this._sharedService.formataDataCSV(x.dataDesembarqueNVOri),
        this._sharedService.formataDataCSV(x.Transbordos[0].dataEtaPotPrev),
        this._sharedService.formataDataCSV(x.Transbordos[0].dataEtaPotConf),
        this._sharedService.formataDataCSV(x.Transbordos[0].dataRealSaidaTransbordo),
        this._sharedService.formataDataCSV(x.Transbordos[0].dataEmbarque),
        this._sharedService.formataDataCSV(x.dataEtaPodPrev),
        this._sharedService.formataDataCSV(x.dataEtaPodConf),
        this._sharedService.formataDataCSV(x.dataDesembarque),
        this._sharedService.formataDataCSV(x.dtSaidaTerminalEnt),
        this._sharedService.formataDataCSV(x.dtPrevEntrega),
        //this._sharedService.formataDataCSV(x.dtPrevDesembarque),
        //this._sharedService.formataDataCSV(x.dtRealDesembarque),
        x.idAgendEntrega != null ? "S" : "N",
        this._sharedService.formataDataCSV(x.dtRealEmEntrega),
        this._sharedService.formataDataCSV(x.dataFimEntrega),
        this._sharedService.formataDataCSV(x.dataDevolucaoVazio),
        x.notaFiscal,
        x.numCte,
        x.numRefCli,
        x.Transbordos
          ? x.Transbordos.length >= 1
            ? x.Transbordos[0].descViagemTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 1
            ? x.Transbordos[0].nomePortoTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 1
            ? x.Transbordos[0].ufPortoTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 1
            ? this._sharedService.formataDataCSV(
                x.Transbordos[0].dataEtaPotPrev
              )
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 1
            ? this._sharedService.formataDataCSV(
                x.Transbordos[0].dataEtaPotConf
              )
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 2
            ? x.Transbordos[1].descViagemTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 2
            ? x.Transbordos[1].nomePortoTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 2
            ? x.Transbordos[1].ufPortoTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 2
            ? this._sharedService.formataDataCSV(
                x.Transbordos[1].dataEtaPotPrev
              )
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 2
            ? this._sharedService.formataDataCSV(
                x.Transbordos[1].dataEtaPotConf
              )
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 3
            ? x.Transbordos[2].descViagemTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 3
            ? x.Transbordos[2].nomePortoTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 3
            ? x.Transbordos[2].ufPortoTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 3
            ? this._sharedService.formataDataCSV(
                x.Transbordos[2].dataEtaPotPrev
              )
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 3
            ? this._sharedService.formataDataCSV(
                x.Transbordos[2].dataEtaPotConf
              )
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 4
            ? x.Transbordos[3].descViagemTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 4
            ? x.Transbordos[3].nomePortoTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 4
            ? x.Transbordos[3].ufPortoTransbordo
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 4
            ? this._sharedService.formataDataCSV(
                x.Transbordos[3].dataEtaPotPrev
              )
            : ""
          : "",
        x.Transbordos
          ? x.Transbordos.length >= 4
            ? this._sharedService.formataDataCSV(
                x.Transbordos[3].dataEtaPotConf
              )
            : ""
          : "",
        //this._sharedService.formataDataCSV(this._sharedService.corrigeData(x.prevEntrega)),
        this._sharedService.formataDataCSV(x.Transbordos[0].dataEmbarque),
        this._sharedService.formataDataCSV(x.Transbordos[0].dataDesembarque),
        this._sharedService.formataDataCSV(x.Transbordos[1].dataEmbarque),
        this._sharedService.formataDataCSV(x.Transbordos[1].dataDesembarque),
        this._sharedService.formataDataCSV(x.Transbordos[2].dataEmbarque),
        this._sharedService.formataDataCSV(x.Transbordos[2].dataDesembarque),
        this._sharedService.formataDataCSV(x.Transbordos[3].dataEmbarque),
        this._sharedService.formataDataCSV(x.Transbordos[3].dataDesembarque),
      ]);
    });

    this.baseService.DownloadCSV(
      data,
      titulos,
      "Rastreamento de Cargas.csv",
      cabecalho
    );
  }

  filtraListaPorTipoDeEvento(
    trackings: any[],
    tipoEventoDaImagem: string
  ): any[] {
    // todas as cargas (retorna lista completa)
    if (tipoEventoDaImagem == "total") {
      return trackings;
    }

    if (tipoEventoDaImagem == "navegando-pendente")
      return trackings.filter((x) => x.PendenteAgendamentoNavegando);
    if (tipoEventoDaImagem == "desembarcada-pendente")
      return trackings.filter((x) => x.PendenteAgendamentoDesembarcada);

    const tipoEvento = tipoEventoDaImagem.trim().toLowerCase();
    return trackings.filter(
      (x) =>
        x.statusBooking && x.statusBooking.trim().toLowerCase() == tipoEvento
    );
  }

  obtemRotasRastreamentos(
    consulta: TrackingListaRequest
  ): Observable<DetalhesRotasViagens> {
    return this.baseService
      .Post("tracking/rota", consulta).pipe(
      map((response: ValidationResult) => {
        const retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError(
            "Rastreamento - Rota",
            retorno.Erros,
            "error",
            LogFrontFuncionalidade.Rastreamento,
            Operacao.Consulta,
            consulta
          );
        }

        return retorno.Data as DetalhesRotasViagens;
      }),
      catchError((e) => {
        return this.baseService.handleError(e);
      }),);
  }

  ConsultarCo2Total(consulta: Co2TotalRequest): Observable<Co2Total> {
    return this.baseService
    .Post("tracking/co2", consulta).pipe(
    map((response: ValidationResult) => {
      const retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._msgService.addMessageValidationError(
          "Rastreamento - Co2 Total",
          retorno.Erros,
          "error",
          LogFrontFuncionalidade.Rastreamento,
          Operacao.Consulta,
          consulta
        );
      }
      return retorno.Data as Co2Total;
    }),
    catchError((e) => {
      this._msgService.addMessage(
        "Rastreamento - Co2 Total",
        "Não foi possível buscar o Co2 Total",
        "error",
        LogFrontFuncionalidade.Rastreamento
      );
      return this.baseService.handleError(e);
    }),);
  }
}
