
import { SobreComponent } from './public/sobre/sobre.component';
import { LogComponent } from './admin/log/log.component';
import { ParametroComponent } from './admin/parametro/parametro.component';
import { AnelRotaComponent } from './admin/anel-rota/anel-rota.component';
import { CaixaEntradaComponent } from './admin/caixa-entrada/caixa-entrada.component';
import { VincularNfComponent } from './provedor/vincular-nf/vincular-nf.component';
import { OrdemServicoComponent } from './provedor/ordem-servico/ordem-servico.component';
import { RastreamentoComponent } from './cliente/rastreamento/rastreamento.component';
import { DocumentacaoComponent } from './cliente/documentacao/documentacao.component';
import { CotacaoComponent } from './cliente/cotacao/cotacao.component';
import { BookingComponent } from './cliente/booking/booking.component';
import { CadastrarSenhaComponent } from './public/cadastrar-senha/cadastrar-senha.component';
import { ResetSenhaComponent } from './public/reset-senha/reset-senha.component';
import { HomeComponent } from './public/home/home.component';
import { RegistrarComponent } from './public/registrar/registrar.component';
import { FaleConoscoComponent } from './public/fale-conosco/fale-conosco.component';
import { ProgramacaoNaviosComponent } from './public/programacao-navios/programacao-navios.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from "@angular/core";

import { AuthGuard } from './_guards/auth.guard';
import { SolicitarCotacaoComponent } from './cliente/cotacao/solicitar-cotacao/solicitar-cotacao.component';
import { SolicitarBookingComponent } from './cliente/booking/solicitar-booking/solicitar-booking.component';
import { CaixaEntradaDetalheComponent } from './admin/caixa-entrada/caixa-entrada-detalhe/caixa-entrada-detalhe.component';
import { HorasComponent } from './admin/parametro/horas/horas.component';
import { NaviosComponent } from './admin/parametro/navios/navios.component';
import { TiposServicosComponent } from './admin/parametro/tipos-servicos/tipos-servicos.component';
import { TerminaisComponent } from './admin/parametro/terminais/terminais.component';
import { PrioridadesComponent } from './admin/parametro/prioridades/prioridades.component';
import { AnelRotaDetalheComponent } from './admin/anel-rota/anel-rota-detalhe/anel-rota-detalhe.component';


//PERFIL
import { PerfilComponent } from './admin/perfil/perfil.component';
import { PerfilDetalheComponent } from './admin/perfil/perfil-detalhe/perfil-detalhe.component';

//USUARIO
import { UsuarioComponent } from './admin/usuario/usuario.component';
import { UsuarioDetalheComponent } from './admin/usuario/usuario-detalhe/usuario-detalhe.component';


//INSTANT PAYMENT COMPONENTS



// TAXA ADICIONAL
//import { SporadicTaxComponent } from './admin/taxa-adicional/taxa-adicional.component';
//import { SporadicTaxDetalheComponent } from './admin/taxa-adicional/taxa-adicional-detalhe/taxa-adicional-detalhe.component';


// ICMS
import { IcmsComponent } from './admin/icms/icms.component';

// Customer Info Import
import { CustomerInfoImportComponent} from './admin/customer-info-import/customer-info-import.component';


// Substituicao Tributaria
import { SubTributariaCompoent} from './admin/sub-tributaria/sub-tributaria.component';
import { SubTributariaDetalheComponent} from './admin/sub-tributaria/sub-tributaria-detalhe/sub-tributaria-detalhe.component';


// TAXA ADICIONAL
import { TipoTaxaEsporadicaComponent } from './admin/tipo-taxa-esporadica/tipo-taxa-esporadica.component';
import { TipoTaxaEsporadicaDetalheComponent } from './admin/tipo-taxa-esporadica/tipo-taxa-esporadica-detalhe/tipo-taxa-esporadica-detalhe.component';


// PORTO
import { PortoComponent } from './admin/porto/porto.component';
import { PortoDetalheComponent } from './admin/porto/porto-detalhe/porto-detalhe.component';


// TAXA ADICIONAL
import { TaxaEsporadicaComponent } from './admin/taxa-esporadica/taxa-esporadica.component';
import { TaxaEsporadicaDetalheComponent } from './admin/taxa-esporadica/taxa-esporadica-detalhe/taxa-esporadica-detalhe.component';
import { RelatorioPixComponent } from './cliente/booking/relatorios/relatoriopix/relatoriopix.component';
import { Booking2Component } from './cliente/booking/relatorios/relatoriopix/booking2.component';





const appRoutes: Routes = [
    //PUBLICO
    { path: 'programacaonavios', component: ProgramacaoNaviosComponent },
    { path: 'faleconosco', component: FaleConoscoComponent },
    { path: 'registrar', component: RegistrarComponent },
    { path: 'home', component: HomeComponent },
    { path: 'resetsenha', component: ResetSenhaComponent },
    { path: 'cadastrarsenha/:id', component: CadastrarSenhaComponent},
    { path: 'cadastrarsenha', component: CadastrarSenhaComponent},


    // { path: 'programacaonavios', loadChildren: () => import('app/public/programacao-navios/programacao-navios.module').then(m => m.ProgramacaoNaviosModule) },
    // { path: 'faleconosco', loadChildren: () => import('app/public/fale-conosco/fale-conosco.module').then(m => m.FaleConoscoModule) },
    // { path: 'registrar', loadChildren: () => import('app/public/registrar/registrar.module').then(m => m.RegistrarModule) },
    // { path: 'home', loadChildren: () => import('app/public/home/home.module').then(m => m.HomeModule) },
    // { path: 'resetsenha', loadChildren: () => import('app/public/reset-senha/reset-senha.module').then(m => m.ResetSenhaModule) },
    // { path: 'cadastrarsenha', loadChildren: () => import('app/public/cadastrar-senha/cadastrar-senha.module').then(m => m.CadastrarSenhaModule) },
    //{ path: 'sobre', component: SobreComponent },

    //CLIENTE /
    { path: 'booking', component: BookingComponent, canActivate: [AuthGuard] },
    { path: 'booking/solicitar', component: SolicitarBookingComponent, canActivate: [AuthGuard] },
    { path: 'booking/solicitar/:id', component: SolicitarBookingComponent, canActivate: [AuthGuard] },
    { path: 'booking/solicitar/programacao/', component: SolicitarBookingComponent, canActivate: [AuthGuard] },
    { path: 'booking/solicitar/:id/:duplicar', component: SolicitarBookingComponent, canActivate: [AuthGuard] },
    { path: 'booking/solicitar/cotacao/:numProposta/:idTrecho/:cnpjCli/:ieCli', component: SolicitarBookingComponent, canActivate: [AuthGuard] },


    { path: 'cotacao', component: CotacaoComponent, canActivate: [AuthGuard] },
    { path: 'cotacao/solicitar', component: SolicitarCotacaoComponent, canActivate: [AuthGuard] },
    { path: 'cotacao/solicitar/:id/:idTrecho', component: SolicitarCotacaoComponent, canActivate: [AuthGuard] },
    { path: 'cotacao/solicitar/:id/:idTrecho/:duplicar', component: SolicitarCotacaoComponent, canActivate: [AuthGuard] },

    { path: 'documentacao', component: DocumentacaoComponent, canActivate: [AuthGuard] },
    { path: 'rastreamento', component: RastreamentoComponent, canActivate: [AuthGuard] },


    // { path: 'booking', loadChildren: () => import('app/cliente/booking/booking.module').then(m => m.BookingModule), canActivate: [AuthGuard] },
    // { path: 'cotacao', loadChildren: () => import('app/cliente/cotacao/cotacao.module').then(m => m.CotacaoModule), canActivate: [AuthGuard] },
    // { path: 'documentacao', loadChildren: () => import('app/cliente/documentacao/documentacao.module').then(m => m.DocumentacaoModule), canActivate: [AuthGuard] },
    // { path: 'rastreamento', loadChildren: () => import('app/cliente/rastreamento/rastreamento.module').then(m => m.RastreamentoModule), canActivate: [AuthGuard] },

    //PROVEDOR
    { path: 'ordemservico', component: OrdemServicoComponent, canActivate: [AuthGuard] },
    { path: 'vincularnf', component: VincularNfComponent, canActivate: [AuthGuard] },

    // { path: 'ordemservico', loadChildren: () => import('app/provedor/ordem-servico/ordem-servico.module').then(m => m.OrdemServicoModule), canActivate: [AuthGuard] },
    // { path: 'vincularnf', loadChildren: () => import('app/provedor/vincular-nf/vincular-nf.module').then(m => m.VincularNfModule), canActivate: [AuthGuard] },

    //ADMIN
    { path: 'caixa-entrada', component: CaixaEntradaComponent, canActivate: [AuthGuard] },
    { path: 'caixa-entrada/detalhe', component: CaixaEntradaDetalheComponent, canActivate: [AuthGuard] },
    { path: 'caixa-entrada/detalhe/:id', component: CaixaEntradaDetalheComponent, canActivate: [AuthGuard] },

    { path: 'parametro', component: ParametroComponent, canActivate: [AuthGuard] , children : [
        { path: 'horas', component: HorasComponent, canActivate: [AuthGuard] },
        { path: 'navios', component: NaviosComponent, canActivate: [AuthGuard] },
        { path: 'servicos', component: TiposServicosComponent, canActivate: [AuthGuard] },
        { path: 'terminais', component: TerminaisComponent, canActivate: [AuthGuard] },
        { path: 'prioridades', component: PrioridadesComponent, canActivate: [AuthGuard] },
        { path: '**', redirectTo: 'horas' },
    ]},
    { path: 'anel-rota', component: AnelRotaComponent, canActivate: [AuthGuard] },
    { path: 'anel-rota/detalhe', component: AnelRotaDetalheComponent, canActivate: [AuthGuard] },
    { path: 'anel-rota/detalhe/:id', component: AnelRotaDetalheComponent, canActivate: [AuthGuard] },

    { path: 'log', component: LogComponent, canActivate: [AuthGuard] },
    { path: 'sobre', component: SobreComponent, canActivate: [AuthGuard] },


    // USUARIO
    { path: 'usuario', component: UsuarioComponent, canActivate: [AuthGuard] },
    { path: 'usuario/detalhe', component: UsuarioDetalheComponent, canActivate: [AuthGuard] },
    { path: 'usuario/detalhe/:id', component: UsuarioDetalheComponent, canActivate: [AuthGuard] },


    // PERFIL
    { path: 'perfil', component: PerfilComponent, canActivate: [AuthGuard] },
    { path: 'perfil/detalhe', component: PerfilDetalheComponent, canActivate: [AuthGuard]  },
    { path: 'perfil/detalhe/:id', component: PerfilDetalheComponent, canActivate: [AuthGuard]  },


    // TAXA ADICIONAL
    { path: 'taxa-esporadica', component: TaxaEsporadicaComponent },
     { path: 'taxa-esporadica/detalhe', component: TaxaEsporadicaDetalheComponent },
     { path: 'taxa-esporadica/detalhe/:id', component: TaxaEsporadicaDetalheComponent  },

    // ICMS
    { path: 'icms', component: IcmsComponent },


    { path: 'customer-info-import', component: CustomerInfoImportComponent },

    { path: 'sub-tributaria', component: SubTributariaCompoent },
    { path: 'sub-tributaria/detalhe', component: SubTributariaDetalheComponent },
    { path: 'sub-tributaria/detalhe/:id', component: SubTributariaDetalheComponent  },


    // TIPO DE TAXA ADICIONAL
    { path: 'tipo-taxa-esporadica', component: TipoTaxaEsporadicaComponent },
    { path: 'tipo-taxa-esporadica-detalhe/detalhe', component: TipoTaxaEsporadicaDetalheComponent },
    { path: 'tipo-taxa-esporadica-detalhe/detalhe/:id', component: TipoTaxaEsporadicaDetalheComponent  },


    // PORTO
    { path: 'porto', component: PortoComponent },
    { path: 'porto-detalhe/detalhe', component: PortoDetalheComponent },
    { path: 'porto-detalhe/detalhe/:id', component: PortoDetalheComponent  },


    { path: 'relatoriopix', component: RelatorioPixComponent},
    { path: 'booking2', component: Booking2Component },


    // { path: 'caixa-entrada', loadChildren: () => import('app/admin/caixa-entrada/caixa-entrada.module').then(m => m.CaixaEntradaModule), canActivate: [AuthGuard] },
    // { path: 'parametro', loadChildren: () => import('app/admin/parametro/parametro.module').then(m => m.ParametroModule), canActivate: [AuthGuard] },
    // { path: 'anel-rota', loadChildren: () => import('app/admin/anel-rota/anel-rota.module').then(m => m.AnelRotaModule), canActivate: [AuthGuard] },
    // { path: 'log', loadChildren: () => import('app/admin/log/log.module').then(m => m.LogModule), canActivate: [AuthGuard] },
    // { path: 'sobre', loadChildren: () => import('app/public/sobre/sobre.module').then(m => m.SobreModule), canActivate: [AuthGuard] },
    // { path: 'usuario', loadChildren: () => import('app/admin/usuario/usuario.module').then(m => m.UsuarioModule), canActivate: [AuthGuard] },
    // { path: 'perfil', loadChildren: () => import('app/admin/perfil/perfil.module').then(m => m.PerfilModule), canActivate: [AuthGuard] },

    //{ path: '', component: HomeComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: 'home' }

]

@NgModule({
    // Passando array de módulos que iremos importar
    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
    ],
    // Seção 4: Aula 32 (Exportando o RouterModule, evitamos o erro ocorrido: 'router-outlet' is not a known element:)
    exports: [
        RouterModule
    ],
})
export class AppRoutingModule { }
